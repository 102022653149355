<template>
  <b-field
    id='datasetselect'
    :type="type"
    :message="message"
  >
    <template #label>
      <b-tooltip
        v-if="labeltooltip"
        position="is-right"
        :label="labeltooltip"
      >
          {{ label }}
      </b-tooltip>
      <div v-else>
          {{ label }}
      </div>
    </template>
    <b-dropdown
      ref="dropdown"
      v-if="withDataset"
      :expanded="withVarname===false"
      :scrollable="true"
      :max-height="200"
      @mouseout.native="emitOut"
      @mouseover.native="emitOver"
      placeholder="Dataset"
      @input="option => changeDataset(option)"
    >
      <template #trigger>
        <b-button
          :expanded="withVarname===false"
          :label="dropdownLabel"
          :type="inType"
          :icon-pack="pack"
          :icon-left="icon"
          :icon-right="iconRight" />
      </template>
      <b-dropdown-item
        v-for="(dataset, index) in availdatasets"
        :key="index"
        :value="dataset"
        aria-role="listitem"
        :disabled="dataset.disabled">
        <div class="media">
          <b-icon class="media-left" pack="icomoon" :icon="icon"></b-icon>
          <div class="media-content">
            <h3><strong>{{dataset.user_name}}</strong></h3>
            <small>{{dataset.meta.ncol}} variables</small>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <b-autocomplete
      v-if="withVarname"
      :data="filteredVarnames"
      open-on-focus
      :disabled="disabled"
      @mouseout.native="emitOut"
      @mouseover.native="emitOver"
      expanded
      placeholder="Select a variable"
      clearable
      v-model="innerVarname"
      field="name"
      @focus="getFilteredVarnames('')"
      @typing="option => getFilteredVarnames(option)"
      @input="option => changeVarname(option)"
      @select="option => selectVarname(option)"
    >
      <template v-if="dataset !== null" #empty>No variables matched</template>
      <template v-else #empty>Select first a dataset</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useUtilsStore } from '@/stores/utils'
export default {
  name: 'DatasetSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    labeltooltip: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    pack: {
      type: String,
      default: 'icomoon'
    },
    dataset: {
      type: String,
      default: null
    },
    varname: {
      type: String,
      default: ''
    },
    normalized: { // filter on normalized value yes/no
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withDataset: { // display dataset field
      type: Boolean,
      default: true
    },
    withVarname: { // display variable field
      type: Boolean,
      default: true
    },
    type: { // type for <b-field
      type: Object,
      default: null
    },
    message: {
      type: Array,
      default: null
    },
    vartype: { // quali or quanti
      type: String,
      default: null
    },
    uniquevals: { // filter variables with max uniquevals
      type: Number,
      default: null
    },
    minNumericVariable: { // filter dataset with number of numerical variable
      type: Number,
      default: 0
    },
    minCatVariable: { // filter dataset with number of numerical variable
      type: Number,
      default: 0
    },
    minVariable: {
      type: Number,
      default: 0
    },
    minSample: { // filter dataset with number of sample
      type: Number,
      default: 0
    },
    minlevel: { // for categorical variable min number of expected level (oposite to uniquevals)
      type: Number,
      default: null
    },
    alldistinct: { // all distinct values (yes or no)
      type: String,
      default: null
    }
  },
  data () {
    return {
      filteredVarnames: [],
      selecteDataset: null,
      innerVarname: '',
      listenAtInput: true
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const utilsStore = useUtilsStore()
    return { workspaceStore, utilsStore }
  },
  watch: {
    dataset: function () {
      if (this.withDataset === false && this.dataset !== '') {
        this.updateListVarname(this.dataset)
      } else {
        this.selecteDataset = this.workspaceStore.datasets.filter(obj => obj.object_name === this.dataset)[0]
      }
    },
    varname: function () {
      if (this.varname !== null && this.withVarname) {
        this.innerVarname = this.varname
      }
    }
  },
  mounted () {
    if (this.dataset !== '' && this.dataset !== null) {
      if (this.withDataset === false) {
        this.updateListVarname(this.dataset)
      } else {
        let o = this.workspaceStore.datasets.filter(obj => obj.object_name === this.dataset)[0]
        this.changeDataset(o)
        if (this.varname !== null && this.withVarname) {
          this.innerVarname = this.varname
        }
      }
    }
  },
  computed: {
    availdatasets: function () {
      let filterDataset = JSON.parse(JSON.stringify(this.workspaceStore.datasets))
      filterDataset = filterDataset.map(obj => {
        obj.disabled = !(obj.meta.nrow >= this.minSample && obj.meta.ncol >= this.minVariable &&
                         obj.meta.nbnum >= this.minNumericVariable && obj.meta.nbcat >= this.minCatVariable)
        return obj
      })
      if (this.normalized !== '') {
        filterDataset = filterDataset.map(obj => {
          obj.disabled = !(obj.meta.normalized === this.normalized || obj.meta.normalized === 'nc')
          return obj
        })
      }
      return filterDataset
    },
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    dropdownLabel: function () {
      let label = 'Dataset'
      if (this.selecteDataset) {
        label = this.selecteDataset['user_name']
        let width = 30
        if (this.$refs.dropdown.$el.clientWidth > 0) { width = parseInt(this.$refs.dropdown.$el.clientWidth / 14) }
        if (label.length > width && width > 0) {
          label = label.substring(0, width) + '...'
        }
      }
      return label
    },
    inType: function () {
      let type = this.type
      type['is-primary is-light'] = true
      if (this.type['is-danger']) {
        type['is-primary is-light'] = false
      }
      return type
    },
    iconRight: function () {
      if (this.pack === 'icomoon') {
        return 'arrow-down'
      } else {
        return 'caret-down'
      }
    }
  },
  methods: {
    emitOver () {
      this.$emit('mouseover')
    },
    emitOut () {
      this.$emit('mouseout')
    },
    changeDataset (dataset) {
      this.$emit('update:dataset', dataset.object_name)
      this.selecteDataset = dataset
      if (this.withVarname) {
        this.updateListVarname(dataset.object_name)
      }
    },
    updateListVarname (datasetName) {
      this.changeVarname('', true)
      if (!(datasetName in this.allVarnames) && this.withVarname) {
        const loadingComponent = this.$buefy.loading.open({
          container: null
        })
        this.utilsStore.getVarnames(datasetName)
          .then(() => {
            loadingComponent.close()
          })
          .catch(error => {
            this.error = error
            loadingComponent.close()
          })
      }
    },
    reset () {
      this.selecteDataset = null
      this.innerVarname = ''
    },
    changeVarname (value, datasetChanged = false) {
      if (this.listenAtInput && value !== null) {
        if (!datasetChanged && value === '') {
          this.selecteDataset = null
          this.filteredVarnames = []
          this.$emit('update:dataset', null)
          this.$emit('reset')
        } else if (datasetChanged) {
          this.innerVarname = ''
          this.listenAtInput = false
        }
        this.$emit('update:varname', value)
      } else {
        this.listenAtInput = true
      }
    },
    selectVarname (value) {
      if (value !== null) {
        this.$emit('select', value)
      }
    },
    getFilteredVarnames (value) {
      if (this.dataset in this.allVarnames) {
        this.filteredVarnames = this.allVarnames[this.dataset].filter((option) => {
          let toReturn = true
          if (this.vartype === 'quanti') {
            toReturn = option.numeric
          } else if (this.vartype === 'quali') {
            toReturn = !option.numeric
          }
          if (toReturn && this.uniquevals !== null) {
            toReturn = option.uniquevals <= this.uniquevals
          }
          if (toReturn && this.minlevel !== null) {
            toReturn = option.uniquevals >= this.minlevel
          }
          if (toReturn && this.alldistinct !== null) {
            toReturn = option.alldistinct === this.alldistinct
          }
          if (toReturn && value !== '') {
            toReturn = option.name
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          }
          return toReturn
        })
      }
    }
  }
}
</script>

<style scoped>
  .button.is-primary.is-light {
    background-color: #DEF4FD;
    color: #1896E1;
  }
    a.dropdown-item.is-active, .dropdown .dropdown-menu .has-link a.is-active, button.dropdown-item.is-active {
    background-color: #f8f6f6;
    color: #000000;
    }
</style>
<style >
  #datasetselect div.autocomplete div.dropdown-content {
    position: absolute;
  }
</style>
